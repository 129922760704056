
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500&display=swap');

.navbars{
    height: 2rem;
    width: 92vw;
    margin: 0 auto;
    padding: 1rem 2rem;
    border-radius: 20px;
    display: flex;
    margin-top: 5px;
    align-items: center;
    justify-content: center;
    background-color: #FFD460;

 

    top: 0;
  
    text-align: center;

  
    box-shadow: 0px 4px 10px 0px #0000001A;

}



.secondhead
{


    font-family: Plus Jakarta Sans;
    color: #2D4059;
    font-size: medium;

text-align: center;

}

@media (max-width: 900px) {
    .navbars
    {
    
    
        font-family: Plus Jakarta Sans;
        color: #2D4059;
        font-size: medium;
        height: 100px;
    text-align: center;
    
    } 
}