@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500&display=swap');

.navbar{
    height: 4.5rem;
    width: 100vw;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(45,64,89,0.98);
    position: sticky;
    top: 0;
    z-index: 5;
    box-shadow: 0px 4px 10px 0px #0000001A;
}
.light{
   
    background-color: rgba(189, 203, 221, 0.98);
    color: aliceblue;
 
}


.visuallyHidden {
    position: absolute; 
    overflow: hidden; 
    clip: rect(0 0 0 0); 
    height: 1px; width: 1px; 
    margin: -1px; padding: 0; border: 0; 
}

.logo:hover
{
 
        transform: scale(1.1);
        border-radius: 10px;
        background-color: #57585a;
 

      
      
}

.desktopMenuListItem {
    margin: 1rem;
    cursor: pointer;
    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
}

.buttonContainer {
    display: flex; /* This will make the children (i.e., buttons) appear side by side */
    gap: 1rem; /* This will give some spacing between the buttons */
}

.desktopMenuBtn {
    background: none;
    border: 2px solid #FFD460;
    display: flex;
    border-radius: 20px;
    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFD460;
    align-items: center;
    justify-content: center;

    padding-right: 5px;
}

.desktopMenuBtn1 {
    background: none;
    border: 2px solid #FFD460;
    display: flex;
    border-radius: 20px;
    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFD460;
    padding: 10px;
    align-items: center;
    justify-content: center;
}

.desktopMenuImg {
   object-fit: cover;
   margin: 10px;
}

.desktopMenuImg1 {
    object-fit: cover;
    padding: 2px;
}

.desktopMenu {
    display: flex; /* This will make the children (i.e., buttons) appear side by side */
    gap: 1rem; /* This will give some spacing between the buttons */
}

.menuIcon {
    cursor: pointer;
    background:none;
    width: 50px;
    height: 80px;
   
}



.hamburgerIcon {

    color: #2D4059; /* Color of the hamburger icon - setting it to the navbar background for contrast */
   
}


.menuIconActive {
    background-color: #2D4059; /* The color you want when the menu is open */
    border: #FFD460 2px solid;
}

.mobileMenu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%; /* Position it just below the navbar */
    left: 0;
    right: 0;
    background-color: rgba(45,64,89,0.98);
    transition: max-height 0.3s ease-in-out; 
    max-height: 0; /* Initial state - fully collapsed */
    overflow: hidden; 
    padding: 10px;
    transition: max-height 5s ease-in-out;
    border-bottom: #FFD460 10px solid;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.mobileMenu.show {
    display: flex;
    max-height: 500px; /* Arbitrary large value for the expanded menu */
}



.mobileMenuListItem {
    margin: 1rem;
    cursor: pointer;
    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFD460;
}

.desktopMenuListItem:hover
{
color: #FFD460;
transform: scale(1.1);
}

.desktopMenuBtn:hover
{
    background-color:#FFD460;
    color: #2D4059;
}

/* Media query for small screens */
@media (max-width: 900px) {
    .navbar{
        height: 5rem;
        width: 102%;
        margin: 0 auto;
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(45,64,89,0.98);
        position: sticky;
        top: 0;
        z-index: 5;
        box-shadow: 0px 4px 10px 0px #0000001A;
    }
    
    .desktopMenu {
        display: none;  /* Hide desktop menu on small screens */
    }

    .desktopMenuBtn
    {
        display: none;
    }


    .menuIcon {
        display: block;
        
    }

    .menuIconActive {
        background-color: #2D4059; /* The color you want when the menu is open */
        border: #FFD460 2px solid;
    }

    .desktopMenuBtn1 {
        cursor: pointer;
    display: none; /* Initially hidden for large screens */
    font-size: 24px;
    border-radius: 50%; /* Makes the div a circle */
    
    width: 40px;  /* Width of the circle */
    height: 40px; /* Height of the circle */
    display: flex; 
    align-items: center; /* These two lines will center the hamburger icon */
    justify-content: center;
    }

    .socials
    {   display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;
    }

    .soc
    {
       
       font-size: xx-large;
    }

    /* GENERAL STYLES */
.visuallyHidden {
    position: absolute; 
    overflow: hidden; 
    clip: rect(0 0 0 0); 
    height: 1px; width: 1px; 
    margin: -1px; padding: 0; border: 0; 
}



.hamburger {
    margin:auto;
    right: 260%;
    margin: 25px;
    width: 30px;
    height: 30px;
    position: relative;
}

.hamburger .bar {
    padding: 0;
    width: 30px;
    height: 4px;
    background-color:#FFD460;
    display: block;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
    position: absolute; 
}

.bar1 {
    top: 0;
}

.bar2,
.bar3 {
    top: 13.5px;
}

.bar3 {
    right: 0;
}

.bar4 {
    bottom: 0;
}

/* HAMBURGER 1 */
.checkbox1:checked + label > .hamburger1 > .bar1{
    transform: rotate(45deg);
    transform-origin: 5%;
    width: 41px
}

.checkbox1:checked + label > .hamburger1 > .bar2 {
    transform: translateX(-40px);
    background-color: transparent;
}

.checkbox1:checked + label > .hamburger1 > .bar3 {
    transform: translateX(40px);
    background-color: transparent;
}

.checkbox1:checked + label > .hamburger1 > .bar4 {
    transform-origin: 5%;
    transform: rotate(-45deg);
    width: 41px;
}

/* HAMBURGER 2 */

.checkbox2:checked + label > .hamburger2 > .bar1{
    transform: translateX(40px);
    background-color: transparent;
}

.checkbox2:checked + label > .hamburger2 > .bar2{
    transform: rotate(45deg);
}

.checkbox2:checked + label > .hamburger2 > .bar3{
    transform: rotate(-45deg);
}

.checkbox2:checked + label > .hamburger2 > .bar4{
    transform: translateX(-40px);
    background-color: transparent;
}

/* HAMBURGER 3 */

.hamburger3 .bar1 {
    transform-origin: 5%;
}

.hamburger3 .bar4 {
    transform-origin: 5%;
}

.checkbox3:checked + label > .hamburger3 > .bar1{
    transform: rotate(45deg);
    height: 3px;
    width: 42px;
}

.checkbox3:checked + label > .hamburger3 > .bar3{
    transform: rotate(45deg);
    height: 3px;
    background-color: transparent;
}

.checkbox3:checked + label > .hamburger3 > .bar2{
    transform: rotate(-45deg);
    height: 3px;
    background-color: transparent;
}

.checkbox3:checked + label > .hamburger3 > .bar4{
    transform: rotate(-45deg);
    height: 3px;
    width: 42px;
}

/* HAMBURGER 4 */

.hamburger2 .bar2,
.hamburger3 .bar3,
.hamburger4 .bar4 {
    top: 13.5px;
}

.hamburger4 .bar5 {
    bottom: 0px;
}

.hamburger4 .bar {
    transition: all 0.4s ease-in-out, transform 0.4s ease-in-out 0.4s;
}

.hamburger4 .bar2 {
    width: 1px;
    transform: rotate(90deg);
    left: 13.5px;
}

.hamburger4 .bar3 {
    width: 1px;
    left: 13.5px;
    
}

.checkbox4:checked + label > .hamburger4 > .bar1{
    top: 13.5px;
    background-color: transparent;
}

.checkbox4:checked + label > .hamburger4 > .bar2{
    left: 0px;
    width: 30px;
    transform: rotate(45deg);
}

.checkbox4:checked + label > .hamburger4 > .bar3{
    left: 0;
    width: 30px;
    transform: rotate(-45deg);
}

.checkbox4:checked + label > .hamburger4 > .bar4{
    background-color: transparent;
}

.checkbox4:checked + label > .hamburger4 > .bar5{
    bottom: 13.5px;
    background-color: transparent; 
}


}
