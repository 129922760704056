.aboutMain {
    display: flex;
    align-items: center; /* Center children vertically */
    justify-content: center; /* Center children horizontally */
    min-height: 85vh;
    
    margin: 20px;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.aboutLeft  {
    position: relative; /* to allow absolute positioning of children */
    display: flex;
    margin-top: 30px;
    z-index: 1;
    justify-content: center; /* center content horizontally */
    align-items: center; /* center content vertically */
    width: 30%;
}

.aboutRight
{   margin-top: 80px;
    position: relative; /* to allow absolute positioning of children */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* center content horizontally */
    align-items: flex-start; /* center content vertically */
   width: 35%;
}

.backgroundLogo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-60%, -40%); /* perfectly center the images */
    z-index: 1; /* ensure that the logo is behind the photo */
}

.foregroundPhoto {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-60%, -38%); /* perfectly center the images */
    z-index: 2; /* ensure the photo is above the logo */
}

.aboutRightHeader {
    /* Styles specific to the header, if any */
}

.aboutRightContent {
    /* Styles specific to the content, if any */
}

.backgroundLogo img {
    width: 400px;  /* adjust this value as needed */
    height: 400px; /* adjust this value as needed */
    object-fit: cover; /* to maintain aspect ratio */
    border-radius: 50%;
}

.foregroundPhoto img {
    width: 500px;  /* adjust this value as needed */
    height: 500px; /* adjust this value as needed */
    object-fit: cover; /* to maintain aspect ratio */
}

.aboutRightHeader {
    font-size: xx-large;
    margin-bottom: 5px;
    color: #FFD460;
    position: relative; /* To act as a reference for its child's absolute positioning */
}


.line {
    height: 3px;
    background-color: #FFD460;
    width: 200%; /* Adjust as needed */
    position: absolute; /* To allow positioning relative to the aboutRightHeader */
    bottom: 15px; /* Align to the bottom of the aboutRightHeader, adjust as needed */
    left: 180px; /* Start from the left edge */
}

.aboutRightContentOne
{
    margin-bottom: 30px !important;
}

.aboutRightContentTwo
{
    margin-bottom: 20px;
}

.aboutRightContentThree
{
    margin-bottom: 20px;
}

.aboutKeywordsOne
{
color: #FFD460;
}

.aboutLinkedin
{
    border-radius: 10px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #FFD460;
    padding-left: 10px;
    border: 2px solid #FFD460;
    
    font-size: 20px;
}

.linkedinLogo
{
    margin-right: 10px;
}


.aboutLinkedin:hover{
    background-color: #FFD460;
    color:#2D4059 ;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        left: 800px;
        position: relative;
    }
    to {
        opacity: 1;
        left: 0px;
        position: relative;
    }
}

.fade-in {
    animation: fadeIn 1s forwards ;
}


/* Styles for larger screens remain unchanged */

/* Media query for smaller screens */
@media (max-width: 900px) {
   

    .aboutMain {
        flex-direction: column; /* Stack children (aboutLeft and aboutRight) vertically */
        min-height:55rem;
    }
    
    .aboutLeft  {
        top: 50rem;
        width: 100%; /* Take the full width of the parent */
    }

    .aboutRight
    {
        top: 0rem;
        width: 100%;
    }
    
    .aboutRight {
        margin-top: 20px; /* Add some margin on top for spacing */
    }

    .line
    {
        height: 3px;
        background-color: #FFD460;
        width: 50%; /* Adjust as needed */
        position: absolute; /* To allow positioning relative to the aboutRightHeader */
        bottom: 15px; /* Align to the bottom of the aboutRightHeader, adjust as needed */
        left: 180px; /* Start from the left edge */
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        
        }
        to {
            opacity: 1;
            
        }
    }
    
    .fade-in {
        animation: fadeIn 1s forwards ;
        overflow: hidden;
    }
    
    /* Adjust any other styles like font-sizes, margins, etc. here if necessary */
}
