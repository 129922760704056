/* projects.css */

.projectMain {
  display: flex;
  align-items: center; /* Center children vertically */
  justify-content: center; /* Center children horizontally */
  min-height: 50vh; /* Adjusted to 100vh for full viewport height */
  flex-direction: column;
  background-color: #1E2C40;

  width: 100%;



  font-family: 'Plus Jakarta Sans', sans-serif;
}

  
  .featured {
    display: flex;

    justify-content: space-between;
    align-items: center;


    
  
    flex-direction: column;
  }
  


  .mp , .p
  {
    position: relative;
    top: -1.3rem;
    width: 30rem;
    left: 15rem;
  }
  
  
 .imgOne
 {
  width: 600px;
  height: 400px;
  border-radius: 5px;
  margin: 20px;
 
  transition: transform 0.3s ease, filter 0.3s ease;
  
  z-index: -1;
  
  
}

.imgOne:hover
{
  filter:brightness(0.8);

}
.card {
  height: 400px;
  width: 600px;
  border-radius: 10px;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: all ease;
  
  
  cursor: pointer;
  
  z-index: 1;
}




 

  
  .projectFirst,
  .projectSecond {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px;
    margin-bottom: 60px;
   
  
  }

  

 
  .infoContent
  {

    display: flex;
    flex-direction: column;
    width: 400px;
    align-items:flex-end;
  

   
 
  
  }

  .infoContent1
  {

    display: flex;
    flex-direction: column;
    width: 400px;
    align-items:flex-start;
  

   
 
  
  }

  .featuredHead
  {
    float: right;
    color: #FFD460;
    font-size: 20px;
    padding: 10px;

  }


  .projHead
  {
    float: right;
    color: white;
    font-size: 30px;
    margin: 3px;
  }

  .infoContentChild
  {
    background-color: #2D4059;
    padding: 30px;
    border-radius: 8px;
    width: 570px;
   margin-top: 10px;
   margin-bottom: 10px;
   font-weight: 100;
   font-size: medium;
   line-height: 25px;
   z-index: 2;

  }

  .tech span
  {
    margin: 5px;
    float: right;
    padding: 10px;
    color: #FFD460;
  }


  
  .otherProjects {
    display: grid;
   
    grid-template-columns: auto auto auto auto;
   
    margin-top: 20px; /* Add spacing between featured and other projects */

  }
  
  .other {
    width: calc(33.33% - 10px); /* Adjust the width and spacing as needed for 3 columns */
    margin-bottom: 20px; /* Add spacing between other project tiles */
   
    
    /* Add styles for other project tiles */
  }

  .other img
  {
    width: 250px;
    height: 200px;
    border-radius: 10px;
    margin: 10px;
  }

  .other:hover
  {
    filter: opacity(0.8);
  }

  

  .ico
  {
    margin: 5px;
    width: 30px;
    height: 30px;
   
  }

  .ico:hover
  {
    transform: scale(1.2);
  }

  .modal
  {
    background-color:#2D4059;
    width: 1100px;
    height: 550px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    position: absolute;
    border-radius: 10px;
    transition: transform 0.3s ease, filter 0.3s ease;
    animation: closer 0.3s ;
    


  }

  .modal-close
  {
      margin: 10px;
      width: 40px;
      height: 40px;
      border: none;
      background-color: #FFD460;
      color: #2D4059;
      border-radius: 50%;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;      font-size: x-large;
  }

  .modal-close:hover
  {transform: scale(1.1);}

  @keyframes closer
  {
    0%{
      width: 250px;
      height: 200px;
    }

    100%
    {
      width: 1100px;
      height: 550px;
    }
  }

  @keyframes closerA
  {
    0%{
      width: 250px;
      height: 200px;
    }

    100%
    {
      width: 900px;
      height: 420px;
    }
  }

  @keyframes closerB
  {
    0%{
     width: 0px;
     height: 0px;
    }

    100%
    {
      width: 800px;
      height: 100px;
    }
  }

  .modal-content
  {
    display: flex;
    flex-direction: row;

   
  

  }

  iframe
  {
    width: 900px;
    height: 420px;
    margin: 30px;
    border-radius: 10px;
    animation: closerA 0.3s ;
  }
  
 .modal-content span
 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 800px;
  margin: 10px;
 
  font-size: large;

  font-weight: 100;
  line-height: 25px;



  
 }

 .bandTech
 {
  margin: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
 }

 .bandTech span
 {
  color: #FFD460;
  margin: 10px;
 }

 .modalHead
 {
  color: #FFD460;
 }

 .otherHead
 {
  color: #FFD460;
  font-size: xx-large;
  margin-top: 40px;
  position: relative;
  top: -80px;
 }


 .headProj
 {
  color: #FFD460;
  font-size: xx-large;
  margin-bottom: 6px;
  padding: 30px;
 

 }


 @media (max-width: 900px) {

  .projectMain {
    display: flex;
    align-items: center; /* Center children vertically */
    justify-content: center; /* Center children horizontally */
    min-height: 11262vh;
    flex-direction: column;
    min-height: 350vh;
    width: 102%;

    position: relative;
    top: 310px;
    
    background-color: #1E2C40; 
  
    



    
 
    font-family: 'Plus Jakarta Sans', sans-serif;
  }

  .featured {
    display: flex;

    justify-content: space-between;
    align-items: center;
    width: 100%;



    
  
    flex-direction: column;
  }

  .otherProjects {
    display: grid;
   
    grid-template-columns: auto auto ;
   
    margin-top: 20px; /* Add spacing between featured and other projects */

  }

  .projectFirst,
  .projectSecond {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    margin-bottom: 60px;
    font-size: small;

   
  
  }

  .card {
    height: 300px;
    width: 90%;
    border-radius: 10px;
    display: flex;
    margin-left: 10px;

    margin-right: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    transition: all ease;
    
    
    cursor: pointer;
    
    z-index: 1;
  }

  .imgOne
  {
   width: 100%;
   height: 300px;

   border-radius: 5px;
  
   margin: 10px;
   margin-bottom: 30px;
  
   transition: transform 0.3s ease, filter 0.3s ease;
   
   z-index: -1;
   
   
 }

 .infoContentChild
 {
   background-color: #2D4059;
   padding: 20px;
   border-radius: 8px;
   width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  font-weight: 100;
  font-size: medium;
  line-height: 25px;
  z-index: 2;

 }

 .featuredHead
  {
    float: left;
    color: #FFD460;
    font-size: 20px;
    margin: 3px;

  }


  .projHead
  {
    float: left;
    color: white;
    font-size: 30px;
padding: 10px;

  }

  .other img
  {
    width: 150px;
    height: 100px;
    border-radius: 10px;
    margin: 10px;
  }

  .modal
  {
    background-color:#2D4059;
    width:90%;
    height: 900px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    position: absolute;
    border-radius: 10px;
    transition: transform 0.3s ease, filter 0.3s ease;
    animation: none;
    font-size: small;
    z-index: 1;


  }





  .modal-content
  {
    display: flex;
    flex-direction: column;
    font-size: small;

   
  

  }


  iframe
  {
    width: 83%;
    height: 420px;
    margin: 30px;
    border-radius: 10px;
    animation: closerMobile 0.3s ;
  }

  @keyframes closerMobile
  {
    0%{
     width: 0px;
     height: 0px;
    }

    100%
    {
      width: 80%;
      
    }
  }

  @keyframes closerMobile1
  {
    0%{
     width: 0px;
     height: 0px;
    }

    100%
    {
      width: 100%;
      
    }
  }

  .modal-content span
 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 10px;
 
  font-size: small;

  font-weight: 100;
  line-height: 25px;



  
 }

  

 

 

 }


 @media (max-height: 568px) {
  .projectMain {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 340vh;

    font-family: 'Plus Jakarta Sans', sans-serif;
    position: relative;
    padding: 20px;
    top: 450px;
    border-radius: 5px;
    width: 102%;
    margin: 0px;
   

    overflow: hidden;
  }
}


