@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500&display=swap');

.intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: 85vh;
    margin: 20px;
  
    background-image: radial-gradient(rgba(158, 156, 156, 0.8)  2px, transparent 2px); /* #000000 is the dot color; 4px sets the dot size */
    background-size: 60px 60px;
    padding-left: 35%;
    font-family: 'Plus Jakarta Sans', sans-serif; /* Ensure a fallback is provided */
}

/* Your other styles */

.info {
    width: 500px;
    margin-bottom: 30px;
}

.name {
    width: 510px;
    font-size: 88px;
    margin-bottom: -0.2em; /* Adjust this value to control space below "YASH AHIRE" */
}

.introtwo {
    width: 450px;
    font-size: 88px;
    color: rgba(158, 156, 156, 0.4);
    margin-top: -0.2em; /* Adjust this value to control space above "I CODE 4 U" */
  
}

.introtext
{
    font-size: 50px;
}


.auto-type
{
    color: #FFD460;
    font-size: 30px;
}

.download
{

    border-radius: 10px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #FFD460;
    padding-left: 10px;
    border: 2px solid #FFD460;
    
    font-size: 20px;
}

.download:hover
{
    background-color: #FFD460;
    color: #2D4059;
    animation: abtb 1s linear forwards;
}


@keyframes abtb {
    @for $i from 0 through 10 {
        #{$i * 10}% {
            background: linear-gradient(#{60deg + $i * 5deg}, #FFD460 #{($i * 10) + "%"}, rgba(0, 0, 255, 0) 20%);
        }
    }
}

@media (max-width: 900px) { /* This breakpoint is commonly used for tablets and mobile devices */

    .intro {
        padding-left: 10%; /* Reduce the padding for smaller screens */
       
    }

    .info {
        width: 100%; /* Make it adjust according to screen size */
        margin-bottom: 20px;
    }

    .name, .introtwo {
        width: 100%;
        font-size: 48px; /* Adjusting font size for mobile screens */
    }

    .introtext {
        font-size: 32px; /* Adjusting font size for mobile screens */
    }

    .auto-type {
        font-size: 24px; /* Adjusting font size for mobile screens */
    }

    .download {
        padding: 10px 15px; /* Reduced padding for smaller screens */
        font-size: 16px; /* Adjusting font size for mobile screens */
    }

    /* You might also want to adjust the hover effects on smaller screens, but that's up to your design vision */

}

.introUniversity
{
    color: #FFD460;
}


  

