/* projects.css */

.EduMain {
    display: flex;
    align-items: center; /* Center children vertically */
    justify-content: center; /* Center children horizontally */
    min-height: 110vh; /* Adjusted to 100vh for full viewport height */
    flex-direction: column;
    background-color: #1E2C40;
  

  
    width: 100%;
  
  
  
    font-family: 'Plus Jakarta Sans', sans-serif;
  }

  .eLine 
  {
   
    height: 3px;

   



    border-radius: 20px;

    background-color: #FFD460;
  }

  .edu
  {
    position: relative;
    width: 30rem;
    left: 12rem;
    top: -1rem;
  }
  
    
    .featurededu {
      display: flex;
  
      justify-content: space-between;
      align-items: center;
  
  
      
    
      flex-direction: column;
    }
    
    
    
   .imgOneEdu
   {
    width: 350px;
    height: 250px;
 
    border-radius: 5px;
    margin: 20px;
   
    transition: transform 0.3s ease, filter 0.3s ease;
    
    z-index: -1;
    
    
  }
  
  .imgOneEdu:hover
  {
    filter:brightness(0.8);
  
  }

  
  
  
  
   
  
    
    .EduFirst,
    .EduSecond {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px;
      margin-bottom: 10px;
     
     
    
    }

.eduIco
{
    margin-right: 10px;
}
  
    
  
   
    .infoContentEdu
    {
  
      display: flex;
      flex-direction: column;
      width: 440px;
      align-items:flex-end;
    
  
     
   
    
    }
  
    .infoContent1Edu
    {
  
      display: flex;
      flex-direction: column;
      width: 440px;
      align-items:flex-start;
    
  
     
   
    
    }
  
    .featuredHeadEdu
    {
      float: right;
      color: #FFD460;
      font-size: 15px;
      
  
    }

    .headEdu
    {
        font-size: xx-large;
        margin-bottom: 100px;
        color: #FFD460;
    }
  
  
    .eduHead
    {
      float: right;
      color: white;
      font-size: 30px;
      margin: 3px;
    }
  
    .infoContentChildEdu
    {
      background-color: #2D4059;
      padding: 30px;
      border-radius: 8px;
      display: grid;
      grid-template-columns: auto  ;
      width: 450px;
    
     font-weight: 100;
     font-size: medium;

     float: right;
     line-height: 20px;
     z-index: 2;
  
    }
  
    .techEdu span
    {
      margin: 5px;
      float: right;
      padding: 10px;
      color: #FFD460;
    }
  
   
  
   
  
    
  
   
  
   
  
    
  
    @media (max-width: 900px) {

        .eLine
        {
            display: none;
        }

        .EduMain {
            display: flex;
            align-items: center; /* Center children vertically */
            justify-content: center; /* Center children horizontally */
            min-height: 100rem; /* Adjusted to 100vh for full viewport height */
          
            position: relative;
            top: 15rem;
  
            /* background-color: #1E2C40; */
          
        
          
            width: 102%;
          
          
          
            font-family: 'Plus Jakarta Sans', sans-serif;
          }

          .featurededu {
            display: flex;
        
            justify-content: space-between;
            align-items: center;
        
        
            
          
            flex-direction: column;
          }

          .EduFirst,
          .EduSecond {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0px;
            margin-bottom: 10px;
           
           
          
          }

          .infoContentEdu
          {
            width: 95%;
          }
          .featuredHeadEdu{width:95% ;
       font-size: small;}
          .eduHead{width: 90%;
        font-size: x-large;
    margin-bottom: 15px;}
          .infoContentChildEdu{width: 95%;}
          .techEdu{float: left;}
          .headEdu
          {
              font-size: xx-large;
             margin-bottom: 10px;
              color: #FFD460;
          }

    }