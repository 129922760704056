.footMain
{
    display: flex;
    align-items: center; /* Center children vertically */
    justify-content: center; /* Center children horizontally */
    min-height: 40rem; /* Adjusted to 100vh for full viewport height */

    background-color: #1E2C40;
  

  
    width: 100%;
  
  
  
    font-family: 'Plus Jakarta Sans', sans-serif;
   
}

.footElements
{
    display: flex;
    flex-direction: row;


    align-items: center;
    justify-content: center;
    padding: 10px;
}
.footEl
{
width: 22rem;
align-items: center;
justify-content: center;
display: flex;
flex-direction: column;
height: 29rem;
border-radius: 10px;
background-color: #2D4059;
margin: 15px;
}

.music
{width: 20rem;
    height: 22rem;
  
}
.changeMus
{
    display: flex;
    flex-direction: row;

    padding: 1px;
    align-items: center;
    height: 2rem;
    justify-content: center;
    width: 20rem;
    margin: 5px;

}

.changeMus button
{
height: 30px;
width: 30px;
margin: 2px;
border: none;
background-color:#1E2C40;
border-radius: 50%;
}

.changeMus button:hover
{
    background-color:#FFD460 ;
    transform: scale(1.02);
}

.chat
{
    background-color: #2D4059;
    position: relative;
    z-index: 0;


}

.linkedFoot
{
    padding: 10px;
    background-color:#5a7fb0;
    border-radius: 5px;
    width: 200px;
}

.linkedFoot:hover
{
    transform: scale(1.05);
}

.linkedFoot span
{
    margin-left: 20px;
}

form
{
    display: flex;
    flex-direction: column;
}

form input
{
    background-color:#1E2C40;
    color: white;
    text-align: center;
    border: none;
    width: 200px;
    height: 30px;
    margin: 10px;
    border-radius:5px;
    padding: 2px;
}

form input:hover
{
    background-color: #5a7fb0;
}


form textarea
{
    background-color:#1E2C40;
    color: white;
    border: none;
    width: 200px;
    height: 80px;
    margin: 10px;
    border-radius:5px;
    padding: 2px;
    text-align: center;
}

.port
{
    display: flex;
    flex-direction: row;
    background-color:#1E2C40;
    margin: 3px;
    width: 20rem;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 4rem;
}

.socialb
{
padding: 10px;
border:#5a7fb0 1px solid;
}

.port:hover
{
    transform: scale(1.04);
}

.port img
{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 3px;
}


.socl
{
    color: #FFD460;
    font-size: xx-large;
    margin: 5px;
}

.socl:hover
{
    transform: scale(1.1);
}

.op
{
    font-size: x-large;
    margin: 8px;
}
.secZero{
    display: flex;
    justify-content: space-evenly; /* Equal spacing between and around icons */
    align-items: center; /* Center vertically */
    padding: 10px; /* Optional: Add some padding for spacing */
    width: 100%; /* Ensure it spans the container width */
    background-color: #1E2C40;
}

.secZero > * {
    font-size: 24px; /* Adjust icon size as needed */
    color: #FFD460; /* Optional: Set icon color */
    transition: transform 0.2s ease, color 0.2s ease; /* Add hover effects */
}

.secZero > *:hover {
    transform: scale(1.2); /* Slight zoom effect on hover */
    color: #d1d5da; /* Change color on hover */
}
.secOne {
    display: flex;
    justify-content: space-evenly; /* Equal spacing between and around icons */
    align-items: center; /* Center vertically */
    padding: 10px; /* Optional: Add some padding for spacing */
    width: 100%; /* Ensure it spans the container width */
    background-color:#2D4059;
    border-radius: 10px;
}

.secOne > * {
    font-size: 24px; /* Adjust icon size as needed */
    color: #FFD460; /* Optional: Set icon color */
    transition: transform 0.2s ease, color 0.2s ease; /* Add hover effects */
}

.secOne > *:hover {
    transform: scale(1.2); /* Slight zoom effect on hover */
    color: #d1d5da; /* Change color on hover */
}

.secTwo {
    display: flex;
    justify-content:center; /* Equal spacing between and around icons */
    align-items: center; /* Center vertically */
    padding: 10px; /* Optional: Add some padding for spacing */
    width: 100%; /* Ensure it spans the container width */
    background-color:#2D4059;
    border-radius: 10px;
}

.secTwo > * {
    font-size: 24px; /* Adjust icon size as needed */
    color: #FFD460; /* Optional: Set icon color */
    transition: transform 0.2s ease, color 0.2s ease; /* Add hover effects */
    margin: 10px;
}

.secTwo span{
    font-size: medium;
}


.secThree{
    display: flex;

    flex-direction: column; /* Align items vertically */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    padding: 10px; /* Optional: Add some padding for spacing */
    width: 80%; /* Ensure it spans the container width */
    background-color:#1E2C40; /* Optional: Background color */
    border-radius: 10px; /* Rounded corners */
    height: 60%;
    gap: 10px; /* Optional: Add spacing between items */
    margin: 10px;


}
.secThree span{
    font-size: medium;
    color: #5a7fb0;
}

@media (max-width: 900px) {
    .footMain
{
    display: flex;
    align-items: center; /* Center children vertically */
    justify-content: center; /* Center children horizontally */
    height: 122rem; /* Adjusted to 100vh for full viewport height */

    background-color: #1E2C40;
  

  
    width: 102%;
  
  
  
    font-family: 'Plus Jakarta Sans', sans-serif;
}


.footElements
{
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    padding: 10px;
}


.chat
{
    z-index: 1;
  
}


}