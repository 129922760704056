.expMain {
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 190vh;


    background-color: #1E2C40;
    font-family: 'Plus Jakarta Sans', sans-serif;
    overflow-x: hidden;
    width: 100%;
    z-index: 2;
  }
  
  .timeline {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Adjust the spacing between timeline items */
    width: 60%;
  }
  
  .timeline-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start; /* Align items at the top for larger screens */
 
    opacity: 1;
  }
  
  .timeline-img {
    display: flex;
    flex-direction: row;
  }
  
  .timeline-img img {
    border: #FFD460 1px solid;
    border-radius: 10px;
    left: -110px;
    width: 200px;
    position: relative;
  }
  
  .timeline-content {
    max-width: 80%; /* Adjust the content width as needed */
    width: 90%;
    left: -40px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .date {
    color: #646464;
  }
  
  .CompanyName {
    font-size: x-large;
  }
  
  .role {
    color: #FFD460;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .desc {
    margin-bottom: 20px;
    width: 100%; /* Reset width for content */
  }
  
  .vline {
  
    background-color: #9c9b99;
    width: 2px;
    min-height: 140vh; /* Adjust as needed */
    position: relative;
    bottom: 0px; /* Align to the bottom of the aboutRightHeader, adjust as needed */
    left: 180px; /* Start from the left edge */
  }
  
  .hline {
    background-color: #FFD460;
    width: 90px;
    height: 2px; /* Adjust as needed */
    position: relative;
    top: 90px;
    left: -110px;
  }
  
  .circle {
    position: relative;
    left:   79px;
    width: 20px;
    height: 20px;
    top: -8px;
  }
  
  .expHead {
    font-size: xx-large;
    margin-bottom: 10px;
    color: #FFD460;
  }

  .curr
  {
    font-size: small;
    background-color: #FFD460;
    color: #1E2C40;
    border-radius: 10px;
    padding: 4px;
  }

  .line1 {
    height: 3px;
    background-color: #FFD460;
    width: 60%; /* Adjust as needed */
    position: absolute; /* To allow positioning relative to the aboutRightHeader */
    bottom: 212px; /* Align to the bottom of the aboutRightHeader, adjust as needed */
    left: 200px; /* Start from the left edge */
}

@keyframes fadeIna {
    from {
        opacity: 0;
        top: 500px;
        position: relative;
    }
    to {
        opacity: 1;
        top: 0px;
        position: relative;
    }
}

.fade-ina {
    animation: fadeIna 1s forwards ;
}



  
  /* Media Query for smaller screens (adjust as needed) */
  @media (max-width: 900px) {
    .expMain {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 390vh;
      background-color: #1E2C40;
      font-family: 'Plus Jakarta Sans', sans-serif;
      position: relative;
      padding: 20px;
      top: 450px;
      border-radius: 5px;
      width: 102%;
      margin: 0px;
      overflow: hidden;
    }
    
  
    .expHead {
      font-size: xx-large;
      margin-bottom: 10px;
      color: #FFD460;
      top: -350px;
    
      position: relative;
    }
  
    .timeline-item {
      flex-direction: column; /* Stack items vertically on smaller screens */
      align-items: center; /* Center-align items on smaller screens */
      position: relative;
      
      
      width: 100%;
    }
  
    .timeline-img {
      margin-right: 0; /* Remove margin for image and content alignment */
      margin-bottom: 10px; /* Add spacing between image and content on smaller screens */
      
    }
  
    .timeline {
      display: flex;
      flex-direction: column;
      
      width: 100%;
      
    }
  
    .timeline-img img {
      border: #FFD460 2px solid;
      border-radius: 10px;
      left: 10px;
      position: relative;
      width: 100%;
      max-width: 100%;
      
      
    }
  
    .timeline-content {
      max-width: 100%; /* Adjust the content width as needed */
      margin: 10px;
   
      left: 5px;
      width: 90%;
      
      
      
    }
  
    .vline
    {
        
        left: 0%;
       min-height: 350vh;
     border-radius: 50%;
     
     
    }
  
    .hline {
      width: 80px;
      left: -96%;
    }

    .circle
    {
        display: none;
    }

    .line1 {
        height: 3px;
        background-color: #FFD460;
        width: 28%; /* Adjust as needed */
        position: absolute; /* To allow positioning relative to the aboutRightHeader */
        bottom: 658px; /* Align to the bottom of the aboutRightHeader, adjust as needed */
        left: 210px; /* Start from the left edge */
     
    }

  }

  @media (max-height: 568px) {
    .expMain {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 220vh;

      font-family: 'Plus Jakarta Sans', sans-serif;
      position: relative;
      padding: 20px;
      top: 450px;
      border-radius: 5px;

      width: 102%;
      margin: 0px;
      overflow: hidden;
    }
  }

  

  