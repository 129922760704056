
 
.App {
    cursor: url(../src/media/icons8-sphere-30.png) , auto ;
    

  }
  
  /* Styles for the custom dot cursor, adjust as necessary */
  .circle.cursor-dot {
    width: 10px;
    height: 10px;
    background-color: #000; /* Change color if needed */
    border-radius: 50%;
    /* This makes sure the dot is always on top of other elements */
  }

