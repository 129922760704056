.skillsMain {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Adjust the width as needed */
    margin: 0 auto; /* Center the content horizontally */
    height:40rem;
    background-color: #1E2C40;
    font-family: 'Plus Jakarta Sans', sans-serif;
    flex-direction: column;


   

  }

  .shead
  {
    display: flex;
    flex-direction: row;
    position: relative;
    top: -70px;
    color: #FFD460;
    font-size: xx-large;

  }

  .ski
  {
    position: relative;
    width: 50rem;
    left:1rem;
    top: 1.2rem;
  }

  



  .skillContent
  {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

   
 
  }

  .skillInfo
  {
    display: flex;
    background-color:#2D4059;
    padding: 20px 280px 20px 20px;
    width: 700px;
    height: 380px;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    position: relative;
    left: 150px;
    

  }

  .active
  {
    display: flex;
    flex-direction: column;
   
  }

 
  .disp
  {
    display: none;
  
  }



  .skillsList
  {
   justify-content: flex-start;
   float: left;
   width: 200%;
   position: relative;
   left: -100px;
  }

  .js,.py,.sq,.ht,.cs,.rj,.no,.jq
  {
    background-color: #FFD460;
    color: black;
  }

  .skillsList span
  {
     padding: 5px 30px 5px 30px;
    border-radius: 20px;
  
   
    float: right;
    margin: 5px;
    margin-bottom: 5px;

  }

  .a
  {
    background-color:rgba(134, 131, 131, 0.5);   
    
  }
  .b
  {
    background-color:rgba(134, 131, 131, 0.5);     
    
  }

  .c
  {
    background-color:rgba(134, 131, 131, 0.5);    
    
  }
  .d
  {
    background-color:rgba(134, 131, 131, 0.5);     
    
  }
  .e
  {
    background-color:rgba(134, 131, 131, 0.5);      
    
  }
  .f
  {
    background-color:rgba(134, 131, 131, 0.5);     
    
  }
  .g
  {
    background-color:rgba(134, 131, 131, 0.5);     
    
  }


  .skillsList span:hover
  {
    background-color:#FFD460;
    color: #1E2C40;
  }

  .skillsList div
  {
    margin: 15px;
  }
  
  /* Define the default color for .javascript */
/* Define the default color for .javascript */

  
  .hovered {
    display: block; /* Set the color you want when hovering */
  }

  
.progress {
    width:150px;
    height:15px;
    -webkit-mask:
      radial-gradient(circle closest-side,#000 94%,#0000) 0 0/20% 100%,
      linear-gradient(#000 0 0) center/calc(100% - 12px) calc(100% - 18px) no-repeat;
    animation:p7 2s infinite linear;
  }

  #jsp
  {
    background: linear-gradient(#FFD460 0 0) 0%/80% no-repeat #ddd;

  }


  #psp
  {
    background: linear-gradient(#FFD460 0 0) 0%/60% no-repeat #ddd;

  }

  #sqs
  {
    background: linear-gradient(#FFD460 0 0) 0%/70% no-repeat #ddd;

  }

  #hts
  {
    background: linear-gradient(#FFD460 0 0) 0%/80% no-repeat #ddd;

  }

  #cts
  {
    background: linear-gradient(#FFD460 0 0) 0%/40% no-repeat #ddd;

  }

  #rjs
  {
    background: linear-gradient(#FFD460 0 0) 0%/80% no-repeat #ddd;

  }

  #nd
  {
    background: linear-gradient(#FFD460 0 0) 0%/60% no-repeat #ddd;

  }


  .skillInfoHead
  {
    color: #FFD460;
    margin: 10px 10px 20px 10px;
    font-size: larger;
    font-weight: bolder;
  }

  .skillInfoDesc
  {
    margin: 20px 10px 30px 10px;
    font-size: medium;
    width: 400px;
  }

  .icon
  {

  
    margin: 10px 10px 10px 10px;
    width: 30px;
    height: 30px;
   
   
  
  }

  .sline
  {
    width: 800px;
    height: 3px;
    color: #FFD460;
    background-color: #FFD460;
    position: relative;
    top: 20px;
    margin-left: 10px;
  }

  .listOne
  {
    background-color: #000;
  }
  

  
  
  
  @media (max-width: 900px) {
    .skillsMain {
      display: flex;
      justify-content: center;
      align-items: center;
      /* Adjust the width as needed */
      margin: auto; /* Center the content horizontally */
      height: 60rem;
      min-height: 90px;
      background-color: #1E2C40;
      font-family: 'Plus Jakarta Sans', sans-serif;
      flex-direction: column;
      width: 102%;
      position: relative;
      top: 280px;
     
  
  
     
  
    }

    .sline
    {
      display: none;
    }


  .skillContent
  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 28%;
 

   
 
  }

  .skillsList
  {
   justify-content: flex-start;


   position: relative;
   width: 350px;
   margin-left: -20px;

 
  }

  .skillsList span
  {
   
   font-size: small;
   padding: 5px 10px 5px 10px;
 
  }

  .skillInfo
  {
    display: flex;
    background-color:#2D4059;
    padding: 20px 280px 20px 20px;
    width: 190%;
    height: 440px;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    font-size: small;
    border-radius: 10px;
    position: relative;
    left: -90px;
  }

    
   .skillInfoDesc
   {
    width: 150%;
    font-size: small;
   }




  }



  