.footerMain
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    font-family: 'Plus Jakarta Sans', sans-serif;

  
}

.footerMain span
{
color: #FFD460;

}

.yash
{
    font-weight: bolder;
    font-size: large;
    color: teal;
}