/* projects.css */
img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.DataMain {
    display: flex;
    align-items: center; /* Center children vertically */
    justify-content: center; /* Center children horizontally */
    min-height: 80vh; /* Adjusted to 100vh for full viewport height */
    flex-direction: column;
    background-color:#333;

  

  
    width: 100%;
  
  
  
    font-family: 'Plus Jakarta Sans', sans-serif;
  }

  .eLine 
  {
   
    height: 3px;

   



    border-radius: 20px;

    background-color: #FFD460;
  }

  .edu
  {
    position: relative;
    width: 30rem;
    left: 12rem;
    top: -1rem;
  }

  /* Container for the cards */
.cardContainer {
  display: flex;
  justify-content: space-between; /* Evenly distribute space between cards */
  align-items: flex-start; /* Align items at the top */
  gap: 20px; /* Optional: Add space between cards */
  padding: 10px; /* Optional: Add padding around the container */
  width: 90%;
}

/* Individual card styles */
.frame1 {
  display: flex;
  flex-direction: column; /* Stack title and content vertically */
  align-items: center; /* Center align content inside the card */
  justify-content: flex-start; /* Align content at the top */
  background-color: #f5f5f5; /* Background color for the card */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow effect */
  padding: 10px; /* Space inside the card */
  width: 30%; /* Adjust width to fit three cards in a row */
  text-align: center;
}

.frame1:hover{
  transform: scale(1.02);
}

/* Title styling */
.cardTitle {
  font-size: 1.2rem; /* Adjust font size */
  font-weight: bold;
  margin-bottom: 10px; /* Space between title and content */
  color: #333; /* Text color */
}


/* Placeholder content area */
.iframe1 {
  background-color: #e0e0e0; /* Light gray for placeholder */
  width: 100%;
  height: 190px; /* Adjust height as needed */
  border-radius: 5px; /* Match card style */
  
}

  
    
    .featurededu {
      display: flex;
  
      justify-content: space-between;
      align-items: center;
  
  
      
    
      flex-direction: column;
    }
    
    
    
   .imgOneEdu
   {
    width: 350px;
    height: 250px;
 
    border-radius: 5px;
    margin: 20px;
   
    transition: transform 0.3s ease, filter 0.3s ease;
    
    z-index: -1;
    
    
  }
  
  .imgOneEdu:hover
  {
    filter:brightness(0.8);
  
  }

  
  
  
  
   
  
    
    .EduFirst,
    .EduSecond {
      display: flex;
      flex-direction: row;
      align-items: center;      
      margin: 0px;
      margin-bottom: 10px;
     
     
    
    }

.eduIco
{
    margin-right: 10px;
}
  
    
  
   
    .infoContentEdu
    {
  
      display: flex;
      flex-direction: column;
      width: 440px;
      align-items:flex-end;
    
  
     
   
    
    }
  
    .infoContent1Edu
    {
  
      display: flex;
      flex-direction: column;
      width: 440px;
      align-items:flex-start;
    
  
     
   
    
    }
  
    .featuredHeadEdu
    {
      float: right;
      color: #FFD460;
      font-size: 15px;
      
  
    }

    .headData
    {
        font-size: xx-large;
        margin-bottom: 100px;
        color: #ffffff;
    }
  
  
    .dataHead
    {
      float: right;
      color: white;
      font-size: 30px;
      margin: 3px;
    }
  
    .infoContentChildEdu
    {
      background-color: #2D4059;
      padding: 30px;
      border-radius: 8px;
      display: grid;
      grid-template-columns: auto  ;
      width: 450px;
    
     font-weight: 100;
     font-size: medium;

     float: right;
     line-height: 20px;
     z-index: 2;
  
    }
  
    .techEdu span
    {
      margin: 5px;
      float: right;
      padding: 10px;
      color: #fffcf6;
    }
  
   
  
   
  
    
  
   
  
   
  
    
  
    @media (max-width: 900px) {

      .cardContainer {
        display: flex;
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center align items horizontally */
        gap: 20px; /* Add spacing between items */
        padding: 10px; /* Add padding around the container */
        width: 90%; /* Ensure container takes the full width */
        box-sizing: border-box; /* Include padding in width calculations */
      }
      
      .frame1 {
        display: flex;
        flex-direction: column; /* Stack content inside each card */
        align-items: center; /* Center align items in each card */
        text-align: center; /* Center text if needed */
        width: 100%; /* Make the card take the full width of the container */
      }
      
      .iframe1 img {
        max-width: 100%; /* Make images responsive */
        height: auto; /* Maintain aspect ratio */
        border-radius: 8px; /* Optional: Add rounded corners */
      }
        .eLine
        {
            display: none;
        }

        .DataMain {
            display: flex;
            align-items: center; /* Center children vertically */
            justify-content: center; /* Center children horizontally */
            min-height: 70rem; /* Adjusted to 100vh for full viewport height */
          
            position: relative;
            top: 15rem;
  
            /* background-color: #1E2C40; */
          
        
          
            width: 102%;
          
          
          
            font-family: 'Plus Jakarta Sans', sans-serif;
          }

          .featurededu {
            display: flex;
        
            justify-content: space-between;
            align-items: center;
        
        
            
          
            flex-direction: column;
          }

          .EduFirst,
          .EduSecond {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0px;
            margin-bottom: 10px;
           
           
          
          }

          .infoContentEdu
          {
            width: 95%;
          }
          .featuredHeadEdu{width:95% ;
       font-size: small;}
          .eduHead{width: 90%;
        font-size: x-large;
    margin-bottom: 15px;}
          .infoContentChildEdu{width: 95%;}
          .techEdu{float: left;}
          .headEdu
          {
              font-size: xx-large;
             margin-bottom: 10px;
              color: #FFD460;
          }

    .headData
    {
        font-size: x-large;
        margin-bottom: 100px;
        color: #ffffff;
    }
  

    }